import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ name }) => {
  switch (name) {
    case 'account':
      return (
        <svg viewBox='0 0 16 16' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-519.000000, -909.000000)' fill='#000000'>
              <g transform='translate(519.000000, 909.000000)'>
                <path d='M8,0 C10.2091,0 12,1.79086 12,4 C12,6.2091 10.2091,8 8,8 C5.79086,8 4,6.2091 4,4 C4,1.79086 5.79086,0 8,0 L8,0 Z M8,10 C12.4183,10 16,11.7909 16,14 L16,16 L0,16 L0,14 C0,11.7909 3.58172,10 8,10 L8,10 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'alert':
      return (
        <svg viewBox='0 0 20 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-857.000000, -61.000000)' fill='#000000'>
              <g transform='translate(822.000000, 60.000000)'>
                <g transform='translate(34.000000, 0.000000)'>
                  <path d='M11.9994,11.998 L9.9994,11.998 L9.9994,5.99805 L11.9994,5.99805 L11.9994,11.998 Z M11.9994,15.998 L9.9994,15.998 L9.9994,13.998 L11.9994,13.998 L11.9994,15.998 Z M10.9994,0.99805 C5.47639,0.99805 0.99939,5.47504 0.99939,10.998 C0.99939,16.5211 5.47639,20.998 10.9994,20.998 C16.5224,20.998 20.9994,16.5211 20.9994,10.998 C20.9994,5.47504 16.5224,0.99805 10.9994,0.99805 L10.9994,0.99805 Z' />
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case 'auto-fix':
      return (
        <svg viewBox='0 0 21 21' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-1.000000, -1.000000)' fill='#000000'>
              <path d='M7.49936,4.59817 L4.99936,5.99815 L6.39936,3.49815 L4.99936,0.99815 L7.49936,2.39815 L9.99936,0.99815 L8.59936,3.49815 L9.99936,5.99815 L7.49936,4.59817 L7.49936,4.59817 Z M19.4994,14.398 L21.9994,12.998 L20.5994,15.498 L21.9994,17.998 L19.4994,16.598 L16.9994,17.998 L18.3994,15.498 L16.9994,12.998 L19.4994,14.398 L19.4994,14.398 Z M21.9994,0.99807 L20.5994,3.49807 L21.9994,5.99807 L19.4994,4.59809 L16.9994,5.99807 L18.3994,3.49807 L16.9994,0.99807 L19.4994,2.39805 L21.9994,0.99807 L21.9994,0.99807 Z M13.3424,11.7764 L15.7774,9.3414 L13.6564,7.21936 L11.2214,9.6554 L13.3424,11.7764 L13.3424,11.7764 Z M14.3704,6.29136 L16.7064,8.62738 C17.0974,9.0174 17.0974,9.6504 16.7064,10.0414 L5.04236,21.7054 C4.65136,22.0954 4.01836,22.0954 3.62836,21.7054 L1.29236,19.3694 C0.901362,18.9784 0.901362,18.3454 1.29236,17.9554 L12.9564,6.29136 C13.3474,5.90037 13.9804,5.90037 14.3704,6.29136 L14.3704,6.29136 Z' />
            </g>
          </g>
        </svg>
      );
    case 'calendar-clock':
      return (
        <svg viewBox='0 0 20 22' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-627.000000, -364.000000)' fill='#000000'>
              <g transform='translate(627.000000, 364.000000)'>
                <path d='M12.00064,12.001915 L13.50064,12.001915 L13.50064,14.824915 L15.93884,16.232615 L15.18884,17.531615 L12.00064,15.690915 L12.00064,12.001915 L12.00064,12.001915 Z M16.00004,6.999985 L2.00004,6.999985 L2.00004,18.000015 L6.67335,18.000015 C6.24199,17.091215 6.00064,16.074715 6.00064,15.001915 C6.00064,11.135915 9.13464,8.001915 13.00064,8.001915 C14.07404,8.001915 15.09094,8.243505 16.00004,8.675265 L16.00004,6.999985 L16.00004,6.999985 Z M2,20.000015 C0.894,20.000015 0,19.104015 0,18.000015 L0.01001,3.999995 C0.01001,2.894985 0.894,1.999995 2,1.999995 L3,1.999995 L3,0 L5,0 L5,1.999995 L13.00004,1.999995 L13.00004,0 L15.00004,0 L15.00004,1.999995 L16.00004,1.999995 C17.10304,1.999995 18.00004,2.894985 18.00004,3.999995 L18.00004,10.102315 C19.23754,11.364915 20.00064,13.094315 20.00064,15.001915 C20.00064,18.867915 16.86664,22.001915 13.00064,22.001915 C11.09234,22.001915 9.36244,21.238315 8.09974,20.000015 L2,20.000015 L2,20.000015 Z M13.00064,10.155815 C10.32414,10.155815 8.15444,12.325515 8.15444,15.001915 C8.15444,17.678415 10.32414,19.848115 13.00064,19.848115 C15.67714,19.848115 17.84684,17.678415 17.84684,15.001915 C17.84684,12.325515 15.67714,10.155815 13.00064,10.155815 L13.00064,10.155815 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'procite':
      return (
        <svg viewBox='0 0 16 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-28.000000, -11.000000)'>
              <g transform='translate(28.000000, 11.000000)'>
                <path d='M9.00001,7 L9.00001,1.5 L14.50001,7 L9.00001,7 Z M2,0 C0.89499,0 0.01001,0.89599 0.01001,2 L0,18.00003 C0,19.10403 0.88599,20.00003 1.98999,20.00003 L14.00001,20.00003 C15.10401,20.00003 16.00001,19.10403 16.00001,18.00003 L16.00001,6 L10.00001,0 L2,0 L2,0 Z' />
                <text
                  fontFamily='Noto Sans'
                  fontSize='11'
                  fontWeight='normal'
                  fill='#FFFFFF'
                >
                  <tspan x='3' y='17'>
                    P
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </svg>
      );
    case 'calendar-multiple-check':
      return (
        <svg viewBox='0 0 22 22' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-35.000000, -74.000000)' fill='#000000'>
              <g transform='translate(35.000000, 74.000000)'>
                <path d='M20,16 L20,7.00001 L6,7.00001 L6,16 L20,16 L20,16 Z M20,2.00001 C21.1,2.00001 22,2.90001 22,4.00001 L22,16 C22,17.1 21.1,18 20,18 L6,18 C4.89,18 4,17.1 4,16 L4.01,4.00001 C4.01,2.90001 4.89,2.00001 6,2.00001 L7,2.00001 L7,9.99999997e-06 L8.99999,9.99999997e-06 L8.99999,2.00001 L17,2.00001 L17,9.99999997e-06 L19,9.99999997e-06 L19,2.00001 L20,2.00001 L20,2.00001 Z M16.53,10.06 L12.09,14.5 L9.41,11.82 L10.47,10.76 L12.09,12.38 L15.47,9 L16.53,10.06 L16.53,10.06 Z M2,20 L16,20 L16,22 L2,22 C0.89,22 0,21.1 0,20 L0.01,8 L2,8 L2,20 L2,20 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'comment':
      return (
        <svg viewBox='0 0 20 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g
              transform='translate(10.000000, 9.500000) scale(-1, 1) translate(-10.000000, -9.500000) translate(0.000000, -1.000000)'
              fill='#000000'
            >
              <path d='M7,21 C6.44771,21 6,20.5523 6,20 L5.99999,17 L2,17 C0.89543,17 0,16.1046 0,15 L0.0094,2.99808 C0.0094,1.89308 0.89538,0.99809 1.99939,0.99809 L17.9994,0.99809 C19.1034,0.99809 19.9994,1.89308 19.9994,2.99808 L19.9994,14.9981 C19.9994,16.1021 19.1034,16.9981 17.9994,16.9981 L11.904,16.9997 L8.1979,20.7058 C8.0053,20.8984 7.75367,20.996 7.50123,20.9986 L7.49999,21 L7,21 L7,21 Z' />
            </g>
          </g>
        </svg>
      );
    case 'comment-outline':
      return (
        <svg viewBox='0 0 20 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g
              transform='translate(10.000000, 9.500000) scale(-1, 1) translate(-10.000000, -9.500000) translate(0.000000, -1.000000)'
              fill='#000000'
            >
              <path d='M7,21 C6.44772,21 6,20.5523 6,20 L5.99999,17 L2,17 C0.89543,17 0,16.1046 0,15 L0.0094,2.99808 C0.0094,1.89308 0.89538,0.99809 1.99939,0.99809 L17.9994,0.99809 C19.1034,0.99809 19.9994,1.89308 19.9994,2.99808 L19.9994,14.9981 C19.9994,16.1021 19.1034,16.9981 17.9994,16.9981 L11.904,16.9997 L8.1979,20.7058 C8.0053,20.8984 7.75368,20.996 7.50124,20.9986 L7.5,21 L7,21 L7,21 Z M7.99999,15 L8,18.0753 L11.0753,15 L18,15 L18,3.00002 L1.99999,3.00002 L2,15 L7.99999,15 L7.99999,15 Z' />
            </g>
          </g>
        </svg>
      );
    case 'comment-remove-outline':
      return (
        <svg viewBox='0 0 20 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g
              transform='translate(10.000000, 10.000000) scale(-1, 1) translate(-10.000000, -10.000000)'
              fill='#000000'
            >
              <path d='M7.00021001,20 C6.44791344,20 6.00018001,19.5523428 6.00018001,19.0000955 L6.00017001,16.000382 L2.00006,16.000382 C0.895456864,16.000382 0,15.1050675 0,14.0005729 L0.00940028201,1.99979902 C0.00940028201,0.894904537 0.895406862,0 1.99944998,0 L17.99994,0 C19.1039731,0 20,0.894904537 20,1.99979902 L20,13.9986731 C20,15.1025677 19.1039731,15.9984821 17.99994,15.9984821 L11.9043571,16.000082 L8.19814594,19.7058281 C8.00554017,19.8984097 7.75391262,19.9960004 7.50146504,19.9986001 L7.50022501,20 L7.00021001,20 L7.00021001,20 Z M8.00022001,14.0005729 L8.00023001,17.0755793 L11.0756323,14.0005729 L18.00054,14.0005729 L18.00054,2.00175883 L2.00005,2.00175883 L2.00005,14.0005729 L8.00022001,14.0005729 L8.00022001,14.0005729 Z M7.41440243,4.00153785 L10.0003,6.58708093 L12.5861776,4.00153785 L14.00042,5.41561281 L11.4145424,8.00114589 L14.00042,10.586699 L12.5861776,12.0007639 L10.0003,9.41521085 L7.41443243,12.0007639 L6.00018001,10.586699 L8.58605758,8.00114589 L6.00018001,5.41564281 L7.41440243,4.00153785 L7.41440243,4.00153785 Z' />
            </g>
          </g>
        </svg>
      );
    case 'document':
      return (
        <svg viewBox='0 0 18 18' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-211.000000, -136.000000)' fill='#000000'>
              <g transform='translate(211.000000, 136.000000)'>
                <path d='M2,9.99999997e-06 L16,9.99999997e-06 C17.1046,9.99999997e-06 18,0.89544 18,2.00001 L18,16 C18,17.1046 17.1046,18 16,18 L2,18 C0.89543,18 0,17.1046 0,16 L0,2.00001 C0,0.89544 0.89543,9.99999997e-06 2,9.99999997e-06 L2,9.99999997e-06 Z M4,4.00001 L4,6.00001 L14,6.00001 L14,4.00001 L4,4.00001 L4,4.00001 Z M4,8 L4,10 L14,10 L14,8 L4,8 L4,8 Z M4,12 L4,14 L11,14 L11,12 L4,12 L4,12 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'email':
      return (
        <svg viewBox='0 0 20 16' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-213.000000, -76.000000)' fill='#000000'>
              <g transform='translate(212.000000, 75.000000)'>
                <path d='M18.9994,4.99807 L10.9994,9.9981 L2.9994,4.99807 L2.9994,2.99807 L10.9994,7.9981 L18.9994,2.99807 L18.9994,4.99807 Z M18.9994,0.99807 L2.9994,0.99807 C1.8944,0.99807 1.00939,1.89306 1.00939,2.99807 L0.9994,14.9981 C0.9994,16.1021 1.8944,16.9981 2.9994,16.9981 L18.9994,16.9981 C20.1034,16.9981 20.9994,16.1021 20.9994,14.9981 L20.9994,2.99807 C20.9994,1.89306 20.1034,0.99807 18.9994,0.99807 L18.9994,0.99807 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'endnote':
      return (
        <svg viewBox='0 0 16 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-28.000000, -11.000000)'>
              <g transform='translate(28.000000, 11.000000)'>
                <g>
                  <path d='M9.00001,7 L9.00001,1.5 L14.50001,7 L9.00001,7 Z M2,0 C0.89499,0 0.01001,0.89599 0.01001,2 L0,18.00003 C0,19.10403 0.88599,20.00003 1.98999,20.00003 L14.00001,20.00003 C15.10401,20.00003 16.00001,19.10403 16.00001,18.00003 L16.00001,6 L10.00001,0 L2,0 L2,0 Z' />
                  <text
                    fontFamily='Noto Sans'
                    fontSize='11'
                    fontWeight='normal'
                    fill='#FFFFFF'
                  >
                    <tspan x='3' y='17'>
                      E
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case 'newspaper':
      return (
        <svg viewBox='0 0 20 18' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-131.000000, -627.000000)'>
              <g transform='translate(131.000000, 627.000000)'>
                <path d='M18,8 L2,8 L2,4.99999 L18,4.99999 L18,8 Z M18,10 L11,10 L11,9 L18,9 L18,10 Z M18,12 L11,12 L11,11 L18,11 L18,12 Z M18,14 L11,14 L11,13 L18,13 L18,14 Z M18,16 L11,16 L11,15 L18,15 L18,16 Z M10,16 L2,16 L2,9 L10,9 L10,16 Z M18.333,1.66704 L16.667,-9.99999997e-06 L15,1.66704 L13.333,-9.99999997e-06 L11.667,1.66704 L10,-9.99999997e-06 L8.333,1.66704 L6.66701,-9.99999997e-06 L5,1.66704 L3.33299,-9.99999997e-06 L1.66701,1.66704 L0,-9.99999997e-06 L0,16 C0,17.104 0.89501,18 2,18 L18,18 C19.104,18 19.99,17.104 19.99,16 L20,-9.99999997e-06 L18.333,1.66704 L18.333,1.66704 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'book-multiple-variant':
      return (
        <svg viewBox='0 0 18 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-135.000000, -61.000000)' fill='#000000'>
              <g transform='translate(135.000000, 61.000000)'>
                <path d='M15.9999,16.0007 L6.00002,15.9997 C4.8965,15.9997 4.00002,15.1037 4.00002,13.9997 L3.99999,2.00016 C3.99999,0.89616 4.89647,0.00016 5.99998,0.00016 L15.9999,0.00114 C17.1048,0.00114 17.9998,0.89713 17.9998,2.00114 L17.9999,14.0007 C17.9999,15.1047 17.1049,16.0007 15.9999,16.0007 L15.9999,16.0007 Z M6.99995,7.00066 L8.9999,5.50131 L10.9999,7.00091 L11,2 L7,2 L6.99995,7.00066 L6.99995,7.00066 Z M14,18 L14,20 L2,20 C0.89543,20 0,19.1046 0,18 L0,4.00001 L2,4 L2,18 L14,18 L14,18 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'file-find':
      return (
        <svg viewBox='0 0 16 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-235.000000, -173.000000)' fill='#000000'>
              <g transform='translate(235.000000, 173.000000)'>
                <path d='M5,11 C5,12.66 6.34,14 8,14 C9.66,14 11,12.66 11,11 C11,9.34 9.66,8 8,8 C6.34,8 5,9.34 5,11 L5,11 Z M16,17.59 L16,6 L10,0 L2,0 C0.9,0 0.01,0.9 0.01,2 L0,18 C0,19.1 0.89,20 1.99,20 L14,20 C14.45,20 14.85,19.85 15.19,19.6 L10.76,15.17 C9.96,15.69 9.02,16 8,16 C5.24,16 3,13.76 3,11 C3,8.24 5.24,6 8,6 C10.76,6 13,8.24 13,11 C13,12.02 12.69,12.96 12.17,13.75 L16,17.59 L16,17.59 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'volume':
      return (
        <svg viewBox='0 0 18 18' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-233.000000, -592.000000)'>
              <g transform='translate(232.000000, 592.000000)'>
                <path d='M11.9994,0.22858 L11.9994,2.29156 C14.8904,3.15253 16.9994,5.82855 16.9994,8.9976 C16.9994,12.1675 14.8904,14.8436 11.9994,15.7045 L11.9994,17.7675 C16.0064,16.8566 18.9994,13.2805 18.9994,8.9976 C18.9994,4.71558 16.0064,1.13855 11.9994,0.22858 L11.9994,0.22858 Z M14.4994,8.9976 C14.4994,7.2325 13.4794,5.70752 11.9994,4.97052 L11.9994,13.0256 C13.4794,12.2875 14.4994,10.7635 14.4994,8.9976 L14.4994,8.9976 Z M0.9994,5.99756 L0.9994,11.9976 L4.9994,11.9976 L9.9994,16.9976 L9.9994,0.99756 L4.9994,5.99756 L0.9994,5.99756 L0.9994,5.99756 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'gamepad':
      return (
        <svg
          width='1.2rem'
          height='1rem'
          viewBox='0 0 22 12'
          version='1.1'
          className='icon'
        >
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-229.000000, -773.000000)'>
              <g transform='translate(229.000000, 773.000000)'>
                <path d='M6,0 L16,0 C19.3137,0 22,2.68629 22,6 C22,9.3137 19.3137,12 16,12 C14.223,12 12.6264,11.2275 11.5278,10 L10.4722,10 C9.3736,11.2275 7.777,12 6,12 C2.68629,12 0,9.3137 0,6 C0,2.68629 2.68629,0 6,0 L6,0 Z M5,3 L5,5 L3,5 L3,7 L5,7 L5,9 L7,9 L7,7 L9,7 L9,5 L7,5 L7,3 L5,3 L5,3 Z M14.5,6 C13.6716,6 13,6.6716 13,7.5 C13,8.3284 13.6716,9 14.5,9 C15.3284,9 16,8.3284 16,7.5 C16,6.6716 15.3284,6 14.5,6 L14.5,6 Z M17.5,3 C16.6716,3 16,3.67158 16,4.5 C16,5.3284 16.6716,6 17.5,6 C18.3284,6 19,5.3284 19,4.5 C19,3.67158 18.3284,3 17.5,3 L17.5,3 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'archive':
      return (
        <svg viewBox='0 0 18 18' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-234.000000, -1219.000000)'>
              <g transform='translate(234.000000, 1219.000000)'>
                <path d='M0,0 L18,0 L18,4 L0,4 L0,0 L0,0 Z M1,5.00001 L17,5.00001 L17,18 L1,18 L1,5.00001 L1,5.00001 Z M6.5,8 C6.22386,8 6,8.2239 6,8.5 L6,10 L12,10 L12,8.5 C12,8.2239 11.7761,8 11.5,8 L6.5,8 L6.5,8 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'eye':
      return (
        <svg viewBox='0 0 22 15' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-230.000000, -1031.000000)'>
              <path d='M240.99999,1035.5 C239.34299,1035.5 238,1036.84297 238,1038.49997 C238,1040.15697 239.34299,1041.49997 240.99999,1041.49997 C242.65699,1041.49997 243.99999,1040.15697 243.99999,1038.49997 C243.99999,1036.84297 242.65699,1035.5 240.99999,1035.5 L240.99999,1035.5 Z M240.99999,1043.49997 C238.239,1043.49997 236,1041.26097 236,1038.49997 C236,1035.73901 238.239,1033.5 240.99999,1033.5 C243.76099,1033.5 245.99999,1035.73901 245.99999,1038.49997 C245.99999,1041.26097 243.76099,1043.49997 240.99999,1043.49997 L240.99999,1043.49997 Z M240.99999,1031 C235.998,1031 231.728,1034.11102 230,1038.49997 C231.728,1042.88897 235.998,1045.99997 240.99999,1045.99997 C246.00299,1045.99997 250.27199,1042.88897 251.99999,1038.49997 C250.27199,1034.11102 246.00299,1031 240.99999,1031 L240.99999,1031 Z' />
            </g>
          </g>
        </svg>
      );
    case 'database':
      return (
        <svg viewBox='0 0 16 18' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-557.000000, -635.000000)'>
              <g transform='translate(519.000000, 562.000000)'>
                <g transform='translate(38.000000, 73.000000)'>
                  <path d='M8,0 C3.58172,0 0,1.7909 0,4 C0,6.2091 3.58172,8 8,8 C12.4183,8 16,6.2091 16,4 C16,1.7909 12.4183,0 8,0 L8,0 Z M0,6 L0,9 C0,11.2091 3.58172,13 8,13 C12.4183,13 16,11.2091 16,9 L16,6 C16,8.2091 12.4183,10 8,10 C3.58172,10 0,8.2091 0,6 L0,6 Z M0,11 L0,14 C0,16.2091 3.58172,18 8,18 C12.4183,18 16,16.2091 16,14 L16,11 C16,13.2091 12.4183,15 8,15 C3.58172,15 0,13.2091 0,11 L0,11 Z' />
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case 'forum':
      return (
        <svg viewBox='0 0 20 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-72.000000, -75.000000)' fill='#000000'>
              <g transform='translate(72.000000, 75.000000)'>
                <path d='M15,10 L15,1 C15,0.45 14.55,0 14,0 L1,0 C0.45,0 0,0.45 0,1 L0,15 L4,11 L14,11 C14.55,11 15,10.55 15,10 L15,10 Z M19,4 L17,4 L17,13 L4,13 L4,15 C4,15.55 4.45,16 5,16 L16,16 L20,20 L20,5 C20,4.45 19.55,4 19,4 L19,4 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'multi-result':
      return (
        <svg viewBox='0 0 16 16' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-372.000000, -16.000000)'>
              <g transform='translate(372.000000, 12.000000)'>
                <path d='M9,4 L9,9 L16,9 L16,4 L9,4 Z M9,20 L16,20 L16,11 L9,11 L9,20 Z M0,20 L7,20 L7,15 L0,15 L0,20 Z M0,13 L7,13 L7,4 L0,4 L0,13 L0,13 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'search':
      return (
        <svg viewBox='0 0 16 16' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-950.000000, -28.000000)'>
              <g transform='translate(934.000000, 16.000000)'>
                <g transform='translate(16.000000, 12.000000)'>
                  <path d='M5.77777778,0 C8.9688,0 11.5555556,2.5868 11.5555556,5.77777778 C11.5555556,7.21324444 11.032,8.52648889 10.1655111,9.53697778 L10.4063111,9.77777778 L11.1111111,9.77777778 L15.5555556,14.2222222 L14.2222222,15.5555556 L9.77777778,11.1111111 L9.77777778,10.4063111 L9.53697778,10.1655111 C8.52648889,11.032 7.21324444,11.5555556 5.77777778,11.5555556 C2.5868,11.5555556 0,8.9688 0,5.77777778 C0,2.5868 2.5868,0 5.77777778,0 L5.77777778,0 Z M5.77777778,1.77778667 C3.56864,1.77778667 1.77777778,3.56864889 1.77777778,5.77778667 C1.77777778,7.98693333 3.56864,9.77777778 5.77777778,9.77777778 C7.98693333,9.77777778 9.77777778,7.98693333 9.77777778,5.77778667 C9.77777778,3.56864889 7.98693333,1.77778667 5.77777778,1.77778667 L5.77777778,1.77778667 Z' />
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case 'timetable':
      return (
        <svg viewBox='0 0 20 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-658.000000, -480.000000)'>
              <g transform='translate(519.000000, 289.000000)'>
                <g transform='translate(139.000000, 191.000000)'>
                  <path d='M12,10 L13.5,10 L13.5,12.823 L15.9382,14.2307 L15.1882,15.5297 L12,13.689 L12,10 L12,10 Z M2,9.99999997e-06 L16,2e-05 C17.1046,2e-05 18,0.89545 18,2.00002 L18,8.101 C19.2372,9.3636 20,11.0927 20,13 C20,16.866 16.866,20 13,20 C11.0927,20 9.3636,19.2372 8.101,18 L2,18 C0.89543,18 0,17.1046 0,16 L0,2.00001 C0,0.89544 0.89543,9.99999997e-06 2,9.99999997e-06 L2,9.99999997e-06 Z M2,13 L2,16 L6.67363,16 C6.24169,15.0907 6,14.0736 6,13 L2,13 L2,13 Z M2,6.00001 L8,6 L8,3 L2,3.00001 L2,6.00001 L2,6.00001 Z M16,6.00001 L16,3.00001 L10,3 L10,6 L16,6.00001 L16,6.00001 Z M2,11 L6.28987,11 C6.63282,9.8477 7.2645,8.8197 8.101,8 L2,8 L2,11 L2,11 Z M13,8.1539 C10.3235,8.1539 8.1538,10.3236 8.1538,13 C8.1538,15.6765 10.3235,17.8462 13,17.8462 C15.6764,17.8462 17.8461,15.6765 17.8461,13 C17.8461,10.3236 15.6764,8.1539 13,8.1539 L13,8.1539 Z' />
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case 'book-variant':
      return (
        <svg viewBox='0 0 16 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-279.000000, -61.000000)'>
              <g transform='translate(68.000000, 61.000000)'>
                <g transform='translate(211.000000, 0.000000)'>
                  <path d='M2,2 L7,2 L7,10 L4.5,8.5 L2,10 L2,2 Z M14,0 L2,0 C0.9,0 0,0.9 0,2 L0,18 C0,19.1 0.9,20 2,20 L14,20 C15.1,20 16,19.1 16,18 L16,2 C16,0.9 15.1,0 14,0 L14,0 Z' />
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case 'file':
      return (
        <svg viewBox='0 0 32 40' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-670.000000, -224.000000)'>
              <g transform='translate(670.000000, 224.000000)'>
                <path d='M18.00002,14 L29.00002,14 L18.00002,3 L18.00002,14 L18.00002,14 Z M4,0 L20.00002,0 L32.00002,12 L32.00002,36.00006 C32.00002,38.20806 30.20802,40.00006 28.00002,40.00006 L3.97998,40.00006 C1.77198,40.00006 0,38.20806 0,36.00006 L0.02002,4 C0.02002,1.792 1.78996,0 4,0 L4,0 Z M22.00122,32.00386 L22.00122,28.00386 L3.98124,28.00386 L3.98124,32.00386 L22.00122,32.00386 L22.00122,32.00386 Z M28.00122,24.00386 L28.00122,20.00386 L3.98124,20.00386 L3.98124,24.00386 L28.00122,24.00386 L28.00122,24.00386 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'filmstrip':
      return (
        <svg viewBox='0 0 16 19' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-236.000000, -1142.000000)'>
              <g transform='translate(235.000000, 1142.000000)'>
                <path d='M14.9994,6.99805 L12.9994,6.99805 L12.9994,4.99805 L14.9994,4.99805 L14.9994,6.99805 Z M14.9994,10.998 L12.9994,10.998 L12.9994,8.998 L14.9994,8.998 L14.9994,10.998 Z M14.9994,14.998 L12.9994,14.998 L12.9994,12.998 L14.9994,12.998 L14.9994,14.998 Z M4.99939,6.99805 L2.99939,6.99805 L2.99939,4.99805 L4.99939,4.99805 L4.99939,6.99805 Z M4.99939,10.998 L2.99939,10.998 L2.99939,8.998 L4.99939,8.998 L4.99939,10.998 Z M4.99939,14.998 L2.99939,14.998 L2.99939,12.998 L4.99939,12.998 L4.99939,14.998 Z M14.9994,0.99805 L14.9994,2.99805 L12.9994,2.99805 L12.9994,0.99805 L4.99939,0.99805 L4.99939,2.99805 L2.99939,2.99805 L2.99939,0.99805 L0.99939,0.99805 L0.99939,18.998 L2.99939,18.998 L2.99939,16.998 L4.99939,16.998 L4.99939,18.998 L12.9994,18.998 L12.9994,16.998 L14.9994,16.998 L14.9994,18.998 L16.9994,18.998 L16.9994,0.99805 L14.9994,0.99805 L14.9994,0.99805 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'launch':
      return (
        <svg viewBox='0 0 18 18' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-201.000000, -662.000000)' fill='#000000'>
              <g transform='translate(200.000000, 661.000000)'>
                <path d='M11.9994,0.99805 L11.9994,2.99805 L15.5854,2.99805 L5.7564,12.826 L7.1704,14.241 L16.9994,4.41205 L16.9994,7.99805 L18.9994,7.99805 L18.9994,0.99805 L11.9994,0.99805 Z M16.9994,16.998 L2.9994,16.998 L2.9994,2.99805 L9.9994,2.99805 L9.9994,0.99805 L2.9994,0.99805 C1.8944,0.99805 0.9994,1.89404 0.9994,2.99805 L0.9994,16.998 C0.9994,18.1021 1.8944,18.998 2.9994,18.998 L16.9994,18.998 C18.1034,18.998 18.9994,18.1021 18.9994,16.998 L18.9994,9.998 L16.9994,9.998 L16.9994,16.998 L16.9994,16.998 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'music-note':
      return (
        <svg viewBox='0 0 16 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-236.000000, -1104.000000)'>
              <g transform='translate(236.000000, 1104.000000)'>
                <path d='M8,0 C8.4348,0 8.8373,0.13877 9.1654,0.37444 C11.7769,2.24963 14.3885,4.12481 15.1109,6.31241 C15.8333,8.5 15,11 13.5,13.5 C16,7 9,5.5 9,5.5 L9,16 C9,18.2091 6.9853,20 4.5,20 C2.01472,20 0,18.2091 0,16 C0,13.7909 2.01472,12 4.5,12 C5.02595,12 5.53083,12.0802 6,12.2276 L6,2 C6,0.89543 6.8954,0 8,0 L8,0 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'script':
      return (
        <svg viewBox='0 0 20 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-232.000000, -1067.000000)'>
              <g transform='translate(232.000000, 1067.000000)'>
                <path d='M12,18 C13.1046,18 14,17.1046 14,16 L14,3 L7,2.99999 C6.44771,3 6,3.44771 6,3.99999 L6,14 L3,14 L3,3.00001 C3,1.34316 4.34319,9.99999997e-06 6.00004,9.99999997e-06 L17,9.99999997e-06 C18.6568,9.99999997e-06 20,1.34316 20,3.00001 L20,4 L16,4 L16,16 L16,17 C16,18.6569 14.6568,20 13,20 L3,20 C1.34315,20 0,18.6569 0,17 L0,16 L10,16 C10,17.1046 10.8954,18 12,18 L12,18 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'disc':
      return (
        <svg viewBox='0 0 20 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-230.000000, -652.000000)'>
              <g transform='translate(230.000000, 652.000000)'>
                <path d='M10.00001,0 C4.477,0 0,4.477 0,10.00003 C0,15.52303 4.477,20.00003 10.00001,20.00003 C15.52201,20.00003 20.00001,15.52303 20.00001,10.00003 C20.00001,4.477 15.52201,0 10.00001,0 L10.00001,0 Z' />
                <path
                  d='M10.000006,8 C8.89539737,8 7.999992,8.89540537 7.999992,10.000018 C7.999992,11.1046246 8.89539737,12.00003 10.000006,12.00003 C11.1044126,12.00003 12.000018,11.1046246 12.000018,10.000018 C12.000018,8.89540537 11.1044126,8 10.000006,8 L10.000006,8 Z'
                  fill='#FFFFFF'
                />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'map':
      return (
        <svg viewBox='0 0 18 18' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-234.000000, -1183.000000)'>
              <g transform='translate(233.000000, 1182.000000)'>
                <path d='M12.9994,16.9981 L6.99939,14.8911 L6.99939,2.99807 L12.9994,5.10506 L12.9994,16.9981 Z M18.4994,0.99807 C18.4444,0.99807 18.3924,1.00406 18.3434,1.02306 L12.9994,3.09807 L6.99939,0.99807 L1.3624,2.89606 C1.1524,2.96607 0.99939,3.14707 0.99939,3.37607 L0.99939,18.4981 C0.99939,18.7741 1.22339,18.9981 1.49939,18.9981 C1.55438,18.9981 1.60638,18.9921 1.6564,18.9731 L6.99939,16.8981 L12.9994,18.9981 L18.6374,17.1001 C18.8474,17.0271 18.9994,16.8491 18.9994,16.6191 L18.9994,1.49807 C18.9994,1.22108 18.7764,0.99807 18.4994,0.99807 L18.4994,0.99807 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'map-marker-radius':
      return (
        <svg viewBox='0 0 16 21' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(0.000000, -1.000000)' fill='#000000'>
              <path d='M7.9993,0.99809 C11.3122,0.99809 13.9993,3.66195 13.9993,6.94808 C13.9993,11.4106 7.9993,17.9981 7.9993,17.9981 C7.9993,17.9981 1.99931,11.4106 1.99931,6.94808 C1.99931,3.66195 4.68645,0.99809 7.9993,0.99809 L7.9993,0.99809 Z M8,5 C6.8954,5 6,5.89543 6,7 C6,8.10457 6.8954,9 8,9 C9.1046,9 10,8.10457 10,7 C10,5.89543 9.1046,5 8,5 L8,5 Z M16,18 C16,20.2091 12.4183,22 8,22 C3.58172,22 0,20.2091 0,18 C0,16.7111 1.21924,15.5646 3.11252,14.833 L3.74559,15.7372 C2.66672,16.1892 2,16.8121 2,17.5 C2,18.8807 4.68629,20 8,20 C11.3137,20 14,18.8807 14,17.5 C14,16.8121 13.3333,16.1892 12.2544,15.7371 L12.8875,14.833 C14.7807,15.5646 16,16.7111 16,18 L16,18 Z' />
            </g>
          </g>
        </svg>
      );
    case 'image':
      return (
        <svg viewBox='0 0 40 40' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-806.000000, -224.000000)'>
              <g transform='translate(806.000000, 224.000000)'>
                <path d='M4,0 L36,0 C38.2092,0 40,1.79084 40,3.99998 L40,35.99998 C40,38.20918 38.2092,39.99998 36,39.99998 L4,39.99998 C1.79086,39.99998 0,38.20918 0,35.99998 L0,3.99998 C0,1.79084 1.79086,0 4,0 L4,0 Z M10,3.99998 C7.23858,3.99998 5,6.23856 5,8.99998 C5,11.7614 7.23858,13.99998 10,13.99998 C12.76142,13.99998 15,11.7614 15,8.99998 C15,6.23856 12.76142,3.99998 10,3.99998 L10,3.99998 Z M4,31.99998 L4,35.99998 L36,35.99998 L36,23.99998 L30,17.99998 L18,29.99998 L12,23.99998 L4,31.99998 L4,31.99998 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'image-multiple':
      return (
        <svg viewBox='0 0 20 20' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-250.000000, -323.000000)' fill='#000000'>
              <g transform='translate(68.000000, 61.000000)'>
                <g transform='translate(182.000000, 262.000000)'>
                  <path d='M20.0025,14.0012 L20.0025,1.99875 C20.0025,0.9 19.0987,0.0025 18,0.0025 L5.9975,0.0025 C4.9,0.0025 4.00125,0.9 4.00125,1.99875 L4.00125,14.0012 C4.00125,15.1 4.9,15.9975 5.9975,15.9975 L18,15.9975 C19.0987,15.9975 20.0025,15.1 20.0025,14.0012 L20.0025,14.0012 Z M9.0013,10.0025 L11.0325,12.7125 L14.0012,9.0012 L18,14.0012 L5.9975,14.0012 L9.0013,10.0025 Z M0.0025,4.00125 L0.0025,18 C0.0025,19.0988 0.9,20.0025 1.99875,20.0025 L15.9975,20.0025 L15.9975,18 L1.99875,18 L1.99875,4.00125 L0.0025,4.00125 Z' />
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case 'website':
      return (
        <svg viewBox='0 0 20 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-696.000000, -711.000000)'>
              <g transform='translate(519.000000, 562.000000)'>
                <g transform='translate(177.000000, 148.000000)'>
                  <path d='M14.3607,12.998 C14.4427,12.3411 14.4997,11.6771 14.4997,10.998 C14.4997,10.319 14.4427,9.655 14.3607,8.99805 L17.7367,8.99805 C17.9027,9.6381 17.9997,10.306 17.9997,10.998 C17.9997,11.6901 17.9027,12.358 17.7367,12.998 L14.3607,12.998 Z M12.5927,18.559 C13.1927,17.446 13.6487,16.248 13.9717,14.998 L16.9207,14.998 C15.9617,16.652 14.4297,17.928 12.5927,18.559 L12.5927,18.559 Z M12.3387,12.998 L7.6597,12.998 C7.5647,12.343 7.49969,11.6791 7.49969,10.998 C7.49969,10.317 7.5647,9.6531 7.6597,8.99805 L12.3387,8.99805 C12.4337,9.6531 12.4997,10.317 12.4997,10.998 C12.4997,11.6791 12.4337,12.343 12.3387,12.998 L12.3387,12.998 Z M9.9977,18.962 C9.1657,17.762 8.5157,16.4291 8.0887,14.998 L11.9097,14.998 C11.4817,16.4291 10.8317,17.762 9.9977,18.962 L9.9977,18.962 Z M6.0257,6.99805 L3.07669,6.99805 C4.0347,5.34204 5.56668,4.06403 7.40469,3.43506 C6.80469,4.54803 6.34869,5.74603 6.0257,6.99805 L6.0257,6.99805 Z M3.07669,14.998 L6.0257,14.998 C6.34869,16.249 6.80469,17.4481 7.40469,18.561 C5.56668,17.9321 4.0347,16.6541 3.07669,14.998 L3.07669,14.998 Z M2.26068,12.998 C2.0957,12.358 1.99969,11.6901 1.99969,10.998 C1.99969,10.306 2.0957,9.6381 2.26068,8.99805 L5.6377,8.99805 C5.55569,9.655 5.49969,10.319 5.49969,10.998 C5.49969,11.6771 5.55569,12.3411 5.6377,12.998 L2.26068,12.998 Z M9.9977,3.03406 C10.8317,4.23407 11.4817,5.56702 11.9097,6.99805 L8.0887,6.99805 C8.5157,5.56702 9.1657,4.23407 9.9977,3.03406 L9.9977,3.03406 Z M16.9207,6.99805 L13.9717,6.99805 C13.6487,5.74805 13.1927,4.55005 12.5927,3.43707 C14.4297,4.06805 15.9617,5.34406 16.9207,6.99805 L16.9207,6.99805 Z M9.9937,0.99805 C4.46768,0.99805 -0.00031,5.47504 -0.00031,10.998 C-0.00031,16.5211 4.46768,20.998 9.9937,20.998 C15.5207,20.998 19.9997,16.5211 19.9997,10.998 C19.9997,5.47504 15.5207,0.99805 9.9937,0.99805 L9.9937,0.99805 Z' />
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case 'chevron-down':
      return (
        <svg viewBox='0 0 12 8' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-353.000000, -585.000000)'>
              <g transform='translate(60.000000, 477.000000)'>
                <g transform='translate(292.000000, 108.000000)'>
                  <polygon points='2.41348 0.58407 6.9995 5.1701 11.5855 0.58407 12.9995 1.99807 6.9995 7.9981 0.99948 1.99807' />
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case 'chevron-right':
      return (
        <svg viewBox='0 0 8 12' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(0.000000, -1.000000)' fill='#000000'>
              <polygon points='0.58527 11.584 5.1713 6.998 0.58527 2.41198 1.99927 0.99798 7.9993 6.998 1.99927 12.998' />
            </g>
          </g>
        </svg>
      );
    case 'minus':
      return (
        <svg viewBox='0 0 14 2' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-823.000000, -212.000000)'>
              <g transform='translate(822.000000, 60.000000)'>
                <g transform='translate(0.000000, 151.000000)'>
                  <polygon points='14.9994 2.998 0.99943 2.998 0.99995 1.0001 14.9994 0.998' />
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case 'content-save':
      return (
        <svg viewBox='0 0 18 18' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-203.000000, -1302.000000)'>
              <g transform='translate(203.000000, 1302.000000)'>
                <path d='M12,6 L2,6 L2,2 L12,2 L12,6 Z M9,16 C7.34,16 6,14.66 6,13 C6,11.34 7.34,10 9,10 C10.66,10 12,11.34 12,13 C12,14.66 10.66,16 9,16 L9,16 Z M14,0 L2,0 C0.89,0 0,0.9 0,2 L0,16 C0,17.1 0.89,18 2,18 L16,18 C17.1,18 18,17.1 18,16 L18,4 L14,0 L14,0 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'chart-line':
      return (
        <svg viewBox='0 0 20 18' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-140.000000, -37.000000)'>
              <g transform='translate(140.000000, 37.000000)'>
                <polygon points='14.0071 8.7776 18.2401 1.4457 19.9722 2.4457 14.7391 11.5096 8.2272 7.75 3.4641 16 20 16 20 18 0 18 0 0 2 0 2 14.5359 7.4952 5.01794' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'close':
      return (
        <svg viewBox='0 0 14 14' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-274.000000, -993.000000)'>
              <g transform='translate(60.000000, 918.000000)'>
                <g transform='translate(214.000000, 75.000000)'>
                  <polygon points='14 1.41 12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7' />
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case 'arrow-forward':
      return (
        <svg viewBox='0 0 16 16' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-164.000000, -549.000000)' fill='#000000'>
              <g transform='translate(164.000000, 549.000000)'>
                <polygon points='0 7 0 9 12.0104 9 6.5052 14.5052 7.9194 15.9194 15.8388 8 7.9194 0.08058 6.5052 1.49479 12.0104 7' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'arrow-left':
      return (
        <svg viewBox='0 0 16 16' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g>
              <polygon points='16 7 16 9 3.98958 9 9.4948 14.5052 8.0806 15.9194 0.16116 8 8.0806 0.08058 9.4948 1.49479 3.98958 7' />
            </g>
          </g>
        </svg>
      );
    case 'arrow-right-drop-circle':
      return (
        <svg viewBox='0 0 20 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-231.000000, -692.000000)'>
              <g transform='translate(231.000000, 692.000000)'>
                <path d='M8,14.50005 L8,5.5 L14.00001,10.00005 L8,14.50005 Z M10.00001,0 C4.47699,0 0,4.47699 0,10.00005 C0,15.52305 4.47699,20.00005 10.00001,20.00005 C15.52301,20.00005 20.00001,15.52305 20.00001,10.00005 C20.00001,4.47699 15.52301,0 10.00001,0 L10.00001,0 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'checkbox-checked':
      return (
        <svg viewBox='0 0 18 18' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-240.000000, -957.000000)'>
              <g transform='translate(60.000000, 918.000000)'>
                <g transform='translate(179.000000, 38.000000)'>
                  <path d='M7.9994,14.9981 L2.9994,9.9981 L4.4134,8.5841 L7.9994,12.1701 L15.5854,4.58407 L16.9994,5.99807 L7.9994,14.9981 Z M16.9994,0.99807 L2.9994,0.99807 C1.8934,0.99807 0.9994,1.89307 0.9994,2.99807 L0.9994,16.9981 C0.9994,18.1031 1.8934,18.9981 2.9994,18.9981 L16.9994,18.9981 C18.1044,18.9981 18.9994,18.1031 18.9994,16.9981 L18.9994,2.99807 C18.9994,1.89307 18.1044,0.99807 16.9994,0.99807 L16.9994,0.99807 Z' />
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case 'checkbox-unchecked':
      return (
        <svg viewBox='0 0 18 18' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-61.000000, -957.000000)'>
              <g transform='translate(60.000000, 918.000000)'>
                <g transform='translate(0.000000, 38.000000)'>
                  <path d='M16.9994,0.99807 L2.99939,0.99807 C1.89439,0.99807 0.99939,1.89307 0.99939,2.99807 L0.99939,16.9981 C0.99939,18.1031 1.89439,18.9981 2.99939,18.9981 L16.9994,18.9981 C18.1034,18.9981 18.9994,18.1031 18.9994,16.9981 L18.9994,2.99807 C18.9994,1.89307 18.1034,0.99807 16.9994,0.99807 L16.9994,0.99807 Z M16.9994,2.99807 L16.9994,16.9981 L2.99939,16.9991 L2.99939,2.99807 L16.9994,2.99807 L16.9994,2.99807 Z' />
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case 'checkbox-marked-circle':
      return (
        <svg viewBox='0 0 20 20' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-168.000000, -957.000000)' fill='#000000'>
              <g transform='translate(167.000000, 956.000000)'>
                <path d='M8.99939,15.9981 L3.99939,10.9981 L5.41339,9.5841 L8.99939,13.1701 L16.5854,5.58407 L17.9994,6.99807 L8.99939,15.9981 Z M10.9994,0.99807 C5.47639,0.99807 0.99939,5.47507 0.99939,10.9981 C0.99939,16.5211 5.47639,20.9981 10.9994,20.9981 C16.5214,20.9981 20.9994,16.5211 20.9994,10.9981 C20.9994,5.47507 16.5214,0.99807 10.9994,0.99807 L10.9994,0.99807 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'xml':
      return (
        <svg viewBox='0 0 22 18' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-109.000000, -104.000000)'>
              <g transform='translate(109.000000, 103.000000)'>
                <path d='M11.8931,0.98876 L13.8494,1.40458 L10.1069,19.0112 L8.15065,18.5954 L11.8931,0.98876 L11.8931,0.98876 Z M18.5858,9.9926 L15,6.40686 L15,3.57844 L21.4216,10 L15,16.4103 L15,13.5784 L18.5858,9.9926 L18.5858,9.9926 Z M0.57842,10.0031 L7,3.58148 L7,6.4099 L3.41421,9.9957 L7,13.5815 L7,16.4134 L0.57842,10.0031 L0.57842,10.0031 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'gesture-double-tap':
      return (
        <svg viewBox='0 0 14 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g
              transform='translate(-136.000000, -1089.000000)'
              fillRule='nonzero'
              fill='#6E6E6E'
            >
              <g transform='translate(136.000000, 1089.000000)'>
                <path d='M5,6 C5,5.44771525 5.44771525,5 6,5 C6.55228475,5 7,5.44771525 7,6 L7,10.47 L8.21,10.6 L13.15,12.79 C13.68,13.03 14,13.56 14,14.14 L14,18.5 C13.97,19.32 13.32,19.97 12.5,20 L6,20 C5.62,20 5.26,19.85 5,19.57 L0.1,15.37 L0.84,14.6 C1.03,14.39 1.3,14.28 1.58,14.28 L1.8,14.28 L5,16 L5,6 L5,6 Z M6,2 C8.209139,2 10,3.790861 10,6 C10,7.5 9.2,8.77 8,9.46 L8,8.24 C8.61,7.69 9,6.89 9,6 C9,4.34314575 7.65685425,3 6,3 C4.34314575,3 3,4.34314575 3,6 C3,6.89 3.39,7.69 4,8.24 L4,9.46 C2.8,8.77 2,7.5 2,6 C2,3.790861 3.790861,2 6,2 L6,2 Z M6,0 C9.3137085,8.8817842e-16 12,2.6862915 12,6 C12,7.7 11.29,9.23 10.16,10.33 L9.16,9.88 C10.28,8.96 11,7.56 11,6 C11,3.23857625 8.76142375,1 6,1 C3.23857625,1 1,3.23857625 1,6 C1,8.05 2.23,9.81 4,10.58 L4,11.66 C1.67,10.83 0,8.61 0,6 C-8.8817842e-16,2.6862915 2.6862915,8.8817842e-16 6,0 L6,0 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'shape-plus':
      return (
        <svg viewBox='0 0 20 20' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-393.000000, -303.000000)' fill='#000000'>
              <g transform='translate(393.000000, 303.000000)'>
                <path d='M0,-1e-05 L9,-1e-05 L9,9 L0,9 L0,-1e-05 L0,-1e-05 Z M15.5,1e-05 C17.9853,1e-05 20,2.01473 20,4.50001 C20,6.98529 17.9853,9 15.5,9 C13.0147,9 11,6.98529 11,4.50001 C11,2.01473 13.0147,1e-05 15.5,1e-05 L15.5,1e-05 Z M4.5,12 L9,20 L0,20 L4.5,12 L4.5,12 Z M17,15 L20,15 L20,17 L17,17 L17,20 L15,20 L15,17 L12,17 L12,15 L15,15 L15,12 L17,12 L17,15 L17,15 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'view-list':
      return (
        <svg viewBox='0 0 17 14' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(0.000000, -110.000000)' fill='#000000'>
              <g transform='translate(0.000000, 110.000000)'>
                <path d='M5,0 L5,4 L17,4 L17,0 L5,0 Z M5,14 L17,14 L17,10 L5,10 L5,14 Z M5,9 L17,9 L17,5 L5,5 L5,9 Z M0,4 L4,4 L4,0 L0,0 L0,4 Z M0,14 L4,14 L4,10 L0,10 L0,14 Z M0,9 L4,9 L4,5 L0,5 L0,9 L0,9 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'message':
      return (
        <svg viewBox='0 0 20 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-223.000000, -113.000000)' fill='#000000'>
              <g transform='translate(222.000000, 112.000000)'>
                <path d='M18.9994,0.99807 L2.99938,0.99807 C1.89539,0.99807 1.01038,1.89306 1.01038,2.99807 L0.99938,20.9981 L4.99938,16.9981 L18.9994,16.9981 C20.1029,16.9981 20.9994,16.1021 20.9994,14.9981 L20.9994,2.99807 C20.9994,1.89306 20.1029,0.99807 18.9994,0.99807 L18.9994,0.99807 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'text-message':
      return (
        <svg viewBox='0 0 20 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-22.000000, -11.000000)' fill='#6E6E6E'>
              <g transform='translate(22.000000, 11.000000)'>
                <path d='M17.9975,0.0025 L1.99625,0.0025 C0.8975,0.0025 0.00875,0.9 0.00875,1.99875 L0,20.0025 L3.99875,15.9975 L17.9975,15.9975 C19.0962,15.9975 20,15.1 20,14.0012 L20,1.99875 C20,0.9 19.0962,0.0025 17.9975,0.0025 L17.9975,0.0025 Z M3.99875,6.99875 L15.995,6.99875 L15.995,9.0012 L3.99875,9.0012 L3.99875,6.99875 Z M11.9962,11.9987 L3.99875,11.9987 L3.99875,10.0025 L11.9962,10.0025 L11.9962,11.9987 Z M15.995,5.9975 L3.99875,5.9975 L3.99875,4.00125 L15.995,4.00125 L15.995,5.9975 Z' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'motion-picture':
      return (
        <svg viewBox='0 0 21 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-974.000000, -677.000000)' fill='#000000'>
              <g transform='translate(974.000000, 677.000000)'>
                <g transform='translate(0.000000, 8.000000)'>
                  <path d='M16.3333333,4.5 L16.3333333,1.00003 C16.3333333,0.44803 15.8106667,2.99999999e-05 15.1666667,2.99999999e-05 L1.16666667,2.99999999e-05 C0.522655,2.99999999e-05 0,0.44803 0,1.00003 L0,11 C0,11.552 0.522655,12 1.16666667,12 L15.1666667,12 C15.8106667,12 16.3333333,11.552 16.3333333,11 L16.3333333,7.5 L21,11.5 L21,0.50003 L16.3333333,4.5 L16.3333333,4.5 Z' />
                </g>
                <circle id='Oval' cx='3.5' cy='3.5' r='3.5' />
                <circle id='Oval' cx='12.5' cy='3.5' r='3.5' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'export-ris':
      return (
        <svg viewBox='0 0 16 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-32.000000, -11.000000)' fill='#6E6E6E'>
              <g transform='translate(0.000000, 11.000000)'>
                <path d='M34.0025,0 C32.89375,0 32.01,0.8975 32.01,2.00125 L32,18.0025 C32,19.1062 32.88875,20 33.9925,20 L45.99875,20 C47.10245,20 48.00125,19.1062 48.00125,18.0025 L48.00125,6 L41.99995,0 M40.99875,1.49835 L46.50245,7.00125 L40.99875,7.00125 M36.9325,10.2237 L44.00245,10.2237 L44.00245,17.295 L41.87875,15.175 L39.05125,18.0025 L36.22375,15.175 L39.05125,12.3488' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'pawn':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          className='icon'
        >
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <path d='M17,8a.9.9,0,0,1-1,1H8A.9.9,0,0,1,7,8,.9.9,0,0,1,8,7h.8a4,4,0,0,1-.3-1.5,3.5,3.5,0,0,1,7,0A4,4,0,0,1,15.2,7H16A.9.9,0,0,1,17,8Zm-3,2H10L9,18h6Zm1,9H9L6,20v2H18V20Z' />
          </g>
        </svg>
      );
    case 'plus':
      return (
        <svg viewBox='0 0 14 15' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-75.000000, -225.000000)' fill='#000000'>
              <g transform='translate(74.000000, 225.000000)'>
                <polygon points='14.9994 8.998 8.9994 8.998 8.9994 14.998 6.9994 14.998 6.9994 8.998 0.99936 8.998 0.99936 6.998 6.9994 6.998 6.9994 0.99805 8.9994 0.99805 8.9994 6.998 14.9994 6.998' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'star':
      return (
        <svg className='icon' viewBox='0 0 20 19'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g>
              <path d='M10,13.39603 L6.237,15.66603 L7.232,11.38503 L3.91,8.50703 L8.29,8.13103 L10,4.095 L11.71,8.13103 L16.09,8.50703 L12.768,11.38503 L13.763,15.66603 L10,13.39603 Z M12.809,6.62699 L10,0 L7.191,6.62699 L0,7.244 L5.455,11.97103 L3.82,19.00003 L10,15.27203 L16.18,19.00003 L14.545,11.97103 L20,7.244 L12.809,6.62699 Z' />
            </g>
          </g>
        </svg>
      );
    case 'window-close':
      return (
        <svg viewBox='0 0 14 14' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-283.000000, -264.000000)' fill='#000000'>
              <g transform='translate(283.000000, 264.000000)'>
                <polygon points='8.4579 7.0001 13.9999 12.5433 14 14 12.5426 14.0002 7.0009 8.4573 1.45785 14.0004 -2.66453526e-14 14 -8e-05 12.5442 5.5439 7.0002 -8e-05 1.45765 -2.66453526e-14 -3.99999999e-05 1.45671 0.00021 7.0008 5.5431 12.5437 0.00021 14 -3.99999999e-05 13.9998 1.4583' />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'zotero':
      return (
        <svg viewBox='0 0 16 20' version='1.1' className='icon' focusable='false' role='presentation'>
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-28.000000, -11.000000)'>
              <g transform='translate(28.000000, 11.000000)'>
                <path d='M9.00001,7 L9.00001,1.5 L14.50001,7 L9.00001,7 Z M2,0 C0.89499,0 0.01001,0.89599 0.01001,2 L0,18.00003 C0,19.10403 0.88599,20.00003 1.98999,20.00003 L14.00001,20.00003 C15.10401,20.00003 16.00001,19.10403 16.00001,18.00003 L16.00001,6 L10.00001,0 L2,0 L2,0 Z' />
                <text
                  fontFamily='Noto Sans'
                  fontSize='11'
                  fontWeight='normal'
                  fill='#FFFFFF'
                >
                  <tspan x='3' y='17'>
                    Z
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </svg>
      );
    case 'unknown':
      return (
        <span
          className='icon'
          style={{ fontWeight: 'bold' }}
          role='img'
          aria-label='unknown'
        >
          🤷
        </span>
      );
    default:
      return null;
  }
};

Icon.propTypes = {
  name: PropTypes.string
};

export default Icon;
